import React from "react"
import InteriorContactItem from "../interior-contact-item"
const contactItems = [
  {
    'key': 1,
    'icon': 'icons-32-x-32-export-clock',
    'text': 'De 9:00 - 15:00',
    'field_name': 'schedule'
  },
  {
    'key': 2,
    'icon': 'icons-32-x-32-export-phone-call',
    'text': '(+34) 937 881 661',
    'field_name': 'phone'
  },
  {
    'key': 3,
    'icon': 'icons-32-x-32-export-mail',
    'text': 'info@fundaciotramuntana.es', /* how to get this value dinamically*/
    'field_name': 'email'

  },
  {
    'key': 4,
    'icon': 'icons-32-x-32-export-maps',
    'text': 'Carrer Tramuntana, 1, 08226 Terrassa',
    'field_name': 'address'
  },
  {
    'key': 5,
    'icon': 'icons-32-x-32-export-share',
    'field_name': 'social_media',
    'text': [
        {
          'icon': 'icons-32-x-32-export-twitter',
          'url': 'https://twitter.com/fundaciotramuntana',
          'field_name': "Twitter"
        },
        {
          'icon': 'icons-32-x-32-export-facebook',
          'url': 'https://www.facebook.com/tramuntana.fundacio',
          'field_name': "Facebook"
        },
        {
          'icon': 'icons-32-x-32-export-instagram',
          'url': 'https://www.instagram.com/fundaciotramuntana',
          'field_name': "Instagram"
        }
    ]
  }
]


const getText = (post, generalText, fieldName) => {
  switch (fieldName) {
    case 'email':
        if(post?.field_email){
          return post?.field_email
        }
        return generalText
      break;
    case 'schedule':
        if(post?.field_schedule){
          return post?.field_schedule
        }
      return generalText
    break;
    case 'address':
        if(post?.field_address){
          return post?.field_address
        }
    return generalText
    break;
    default:
      return generalText
  }
}

const Sidebar = ({post}) => {
  return(
    <div className="sidebar col-12 col-md-6 col-lg-3 offset-1 offset-md-0 offset-lg-1  p-0 small-m-b-32 medium-m-b-40 m-b-58">
      {contactItems  && contactItems.length && contactItems.map((item, index) => {
        return(
          <InteriorContactItem
            key={index}
            icon={item.icon}
            text={getText(post, item.text, item.field_name)}
          />
        )
      })}
    </div>
  )
}

export default Sidebar
