import React, {useContext, useState} from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import Description from "../components/section/section-description"
import { LanguageContext } from "../context"
import InteriorContactItem from "../components/interior-contact-item"
import Banner from "../components/banner"
import Video from "../components/video"
import ReactPlayer from 'react-player/youtube'
import Sectionfullwithimage from '../components/section/section-full-with-image'
import Button2 from "../components/button-generic"
import ButtonPrayer from "../components/buttons/button-cta-top-content"
import ComplexContent from "../components/section/complex-content"
import SimpleContent from "../components/section/simple-content"

const PageVolunteerings = ({data, location, pageContext}) => {

    const post = data.nodeVolunteering
    const { t } = useContext(LanguageContext)
    const translationPaths = {
     en: `/en${post?.path?.alias}`,
     es: `/es${post?.path?.alias}`,
     ca: `${post?.path?.alias}`
   }

   //const [isComplex, setIsComplex] = useState(post?.node?.field_show_on_here_for_you)


    return (
        <div className="page-interior">
          <Layout
            translationPaths={translationPaths}
            location={location}
            langcode={post?.langcode}
            >
            <Seo
                title={post?.title}
                lang={post?.langcode}
                description={post?.body?.summary}
                image={post?.relationships?.field_image?.image_style_uri?._653_432}
              />
            <Header
              lang={pageContext?.langcode}
              location={location}
             />


             {
              post?.field_design_type === 'complex'

              ?

              <ComplexContent
                post={post}
                t={t}
               />

              :

              <SimpleContent
                post={post}
                t={t}
               />

              }

              <Footer
              lang={pageContext?.langcode}
              location={location}
             />
          </Layout>
        </div>
    )
}


export const pageQuery = graphql`
query PageVolunteering ($drupal_internal__nid: Int, $langcode: String!){
    nodeVolunteeringTramuntana(drupal_internal__nid: {eq: $drupal_internal__nid}, langcode:{eq: $langcode}){
          id
          drupal_internal__nid
          title
          field_cta {
            title
            uri
          }
          field_design_type
          field_image {
            alt
            title
          }
          relationships {
            field_image{
              image_style_uri{
                _1103x488
              }
            }
          }
          body {
            processed
            summary
            value
          }
          field_weight
          field_form {
            drupal_internal__target_id
          }
        }
    }
`

export default PageVolunteerings
